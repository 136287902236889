import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import sessionExpired from '../../assets/img/sessionExpired.png';
import {FormattedMessage} from "react-intl";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionExpired({open, handleClose}) {
  return (
    <div>
      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='text-center'><FormattedMessage id="Session time expired"/></DialogTitle>
        <DialogContent className='text-center'>
          <img src={sessionExpired} alt="#" width={300} className='mx-auto'/>
          <p id="alert-dialog-slide-description" className='w-80 mx-auto mt-8' style={{fontSize: '1.3em'}}>
            <FormattedMessage id="Your session time has been expired"/>
          </p>
        </DialogContent>
        <DialogActions className={'text-center'}>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
